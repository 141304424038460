<template>
  <el-drawer
    ref="drawer"
    v-loading="loading > 0"
    :custom-class="$style.drawer"
    :visible.sync="visible"
    destroy-on-close
    size="50%"
    title="Datei"
  >
    <div slot="title" :class="$style.titleBar">
      <el-tooltip
        v-if="file.downloadUrl"
        content="Datei herunterladen"
        :tabindex="-1"
      >
        <span :class="$style.titleAction">
          <el-link
            :href="file.downloadUrl"
            :download="file.name"
            icon="el-icon-download"
            type="primary"
            :underline="false"
            @click.native="close()"
          />
        </span>
      </el-tooltip>
      <el-tooltip
        v-if="file.downloadUrl"
        content="Datei in einem neuen Tab öffnen"
        :tabindex="-1"
      >
        <span :class="$style.titleAction">
          <el-link
            :href="file.downloadUrl"
            icon="el-icon-copy-document"
            type="primary"
            :underline="false"
            target="_blank"
            @click.native="close()"
          />
        </span>
      </el-tooltip>
      <el-tooltip
        v-if="file.downloadUrl"
        content="Datei drucken"
        :tabindex="-1"
      >
        <span :class="$style.titleAction">
          <el-button
            :href="file.downloadUrl"
            icon="el-icon-printer"
            type="text"
            @click="print()"
          />
        </span>
      </el-tooltip>
      <span v-if="file.name" :class="$style.title">{{ file.name }}</span>
      <span v-else :class="$style.title">Datei</span>
      <el-button-group v-if="isPdf" :class="$style.directPrintBtns">
        <el-button
          icon="ka-icon-print"
          type="primary"
          round
          @click="directPrint"
        >
          Direkt Drucken
        </el-button>
        <el-tooltip content="Drucker-Setup" :tabindex="-1">
          <el-button
            icon="el-icon-setting"
            type="info"
            round
            @click="showPrinterSetup()"
          />
        </el-tooltip>
      </el-button-group>
    </div>
    <el-container direction="vertical">
      <el-alert
        v-if="documentInfoTitle"
        :closable="false"
        type="info"
        :title="documentInfoTitle"
        :description="documentInfoDescription"
        show-icon
        effect="dark"
      />
      <div v-if="isPdf" ref="pages" :class="$style.pdfPages">
        <pdf-viewer
          v-for="i in numPages"
          :key="i"
          :src="pdfData"
          :page="i"
          :scale.sync="scale"
          :class="$style.pdfPage"
          annotation
          resize
        />
      </div>
      <div v-else :class="$style.lightbox">
        <img :src="lightboxSrc" alt="" :class="$style.lightboxImg" />
      </div>
    </el-container>
  </el-drawer>
</template>

<script>
import PdfViewer, {
  createLoadingTask,
} from "~/src/components/common/uploads/PdfViewer";
import {
  showPrinterSetup,
  TYPE_A4,
  TYPE_PASSPORT,
  TYPE_VOUCHER,
  web2Print,
} from "~/src/util/web2print";

function closePrint() {
  document.body.removeChild(this.__container__);
}

function setPrint() {
  this.contentWindow.__container__ = this;
  this.contentWindow.onbeforeunload = closePrint;
  this.contentWindow.onafterprint = closePrint;
  this.contentWindow.focus(); // Required for IE
  this.contentWindow.print();
}

function printPage(sURL) {
  const oHideFrame = document.createElement("iframe");
  oHideFrame.onload = setPrint;
  oHideFrame.style.position = "fixed";
  oHideFrame.style.right = "0";
  oHideFrame.style.bottom = "0";
  oHideFrame.style.width = "0";
  oHideFrame.style.height = "0";
  oHideFrame.style.border = "0";
  oHideFrame.src = sURL;
  document.body.appendChild(oHideFrame);
}

export default {
  name: "FileDrawer",
  components: { PdfViewer },
  data() {
    return {
      visible: false,
      loading: 0,
      file: {},
      page: 1,
      numPages: 0,
      pdfData: undefined,
      errors: [],
      scale: "page-width",
    };
  },
  computed: {
    isPdf() {
      const { file } = this;
      return file && file.contentType === "application/pdf";
    },
    lightboxSrc() {
      const { file } = this;
      return file && file.lightboxUrl;
    },
    documentInfoTitle() {
      const { file } = this;
      switch (file && file.documentInfo) {
        case "passport":
          return "Pass-Druck";
        case "voucher":
          return "Gutschein-Druck";
      }
      return null;
    },
    documentInfoDescription() {
      const { file } = this;
      switch (file && file.documentInfo) {
        case "passport":
          return "Verwenden Sie den Dokumenten-Drucker (Komdruck-Drucker), um dieses PDF auf einen Blanko-Pass zu drucken. Einstellungen: Randlos drucken, Skalierung 100%.";
        case "voucher":
          return "Verwenden Sie den Dokumenten-Drucker (Komdruck-Drucker), um dieses PDF auf eine Gutschein-Vorlage zu drucken.  Einstellungen: Randlos drucken, Skalierung 100%. Die Gutscheine werden von hinten bedruckt.";
      }
      return null;
    },
  },
  watch: {
    visible(to) {
      if (!to) {
        if (this.file.key) {
          this.close();
        }
      }
    },
  },
  methods: {
    delayedCleanup() {
      if (this.file && typeof this.file.cleanup === "function") {
        const cleanup = this.file.cleanup;
        setTimeout(cleanup, 1000);
      }
    },
    openFor(file) {
      this.delayedCleanup();
      if (this.resolve) {
        this.resolve();
      }
      this.file = file;
      this.page = 1;
      this.numPages = 0;
      this.pdfData = undefined;
      this.visible = true;

      if (this.isPdf) {
        this.pdfData = createLoadingTask(file.downloadUrl);
        this.pdfData
          .then((data) => {
            this.numPages = data.numPages;
          })
          .catch((e) => {
            console.error(e);
          });
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
      });
    },
    close() {
      this.$refs.drawer.closeDrawer();
      this.delayedCleanup();
      this.file = {};
      this.$nextTick(() => {
        if (this.resolve) {
          this.resolve();
          this.resolve = null;
        }
      });
    },
    print() {
      printPage(this.file.downloadUrl);
      // if (typeof this.file.print === "function") {
      //   this.file.print();
      // } else {
      //   printPage(this.file.downloadUrl);
      // }
    },
    directPrint() {
      const { file } = this;
      this.loading += 1;
      function p(blob) {
        switch (file && file.documentInfo) {
          case "passport":
            return web2Print(TYPE_PASSPORT, blob);
          case "voucher":
            return web2Print(TYPE_VOUCHER, blob);
          default:
            return web2Print(TYPE_A4, blob);
        }
      }
      let promise;
      if (file.blob) {
        promise = p(file.blob);
      } else {
        promise = fetch(this.file.downloadUrl, {
          credentials: "same-origin",
          cache: "no-cache",
        })
          .then((r) => r.blob())
          .then(p);
      }
      return promise
        .then((result) => {
          if (result.success) {
            this.visible = false;
          } else {
            this.$alert(result.error, "Fehler beim Drucken");
          }
        })
        .finally(() => {
          this.loading -= 1;
        })
        .catch(this.$apolloErrorHandle);
    },
    showPrinterSetup: showPrinterSetup,
  },
};
</script>

<style lang="module.css" module>
.drawer {
  max-width: 900px;
  min-width: 300px;
  background: #323639;
  color: #fff;
}

.drawer :global(.el-drawer__header) {
  color: #fff;
  padding-bottom: 20px;
  margin-bottom: 0;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.099), 0 4px 8px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.3), 0 2px 6px rgba(0, 0, 0, 0.15);
  position: relative;
}

.drawer :global(.el-drawer__body) {
  display: flex;
  flex-direction: column;
  background: #525659;
}

.titleBar {
  display: flex;
  align-items: center;
}

.titleAction {
  margin-right: 1rem;
}

.titleAction :global(.el-button)
{
  padding: 0;
  border: none;
}

.title {
  flex: 1;
}

.directPrintBtns.directPrintBtns.directPrintBtns.directPrintBtns {
  margin: -10px 10px;
}

.pdfPages {
  padding: 0 20px;
}

.pdfPage {
  width: 100%;
  margin: 20px auto;
  border: 1px solid #646769;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.6);
}

.lightbox {
  padding: 20px;
}

.lightboxImg {
  display: block;
  width: 100%;
  height: auto;
  border: 1px solid #646769;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.6);
}
</style>
